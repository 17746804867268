<template>
  <div id="sp_embed" class="Comment"></div>
</template>
<style>
@import 'https://sitepal.com/api/examples/css/apiExample.css';
</style>
<script>
  export default {

      name: 'SitePal',
      props: ["embed"],
      data: () => ({

        }),
      mounted() {
         let _this = this;
         let recaptchaScript = document.createElement('script');

        recaptchaScript.onload = function() 
        {
          var script = document.createElement('script');
          script.type = "text/javascript";
          script.innerHTML = 'AC_VHost_Embed('+_this.embed+')';
          document.getElementById('sp_embed').appendChild(script);
        };

        recaptchaScript.setAttribute('src', 'https://vhss-d.oddcast.com/vhost_embed_functions_v4.php?acc='+_this.embed.split(',')[0]+'8&js=0');
        document.head.appendChild(recaptchaScript);
      },
      beforeUpdate() {
        console.log(this.embed);
      },

      sayText(txt, voice, lang, engine) {
        window.sayText(txt,voice,lang,engine);
      },
      
      sayAudio(audioName) {
        window.sayAudio(audioName);
      },

      freezeToggle(){
        window.freezeToggle();
      },
      recenter(){
        window.recenter();
      },
      setFacialExpression(expression, amplitude, duration){
        window.setFacialExpression(expression, amplitude, duration)
      },
      clearExpressionList(){
        window.clearExpressionList( )
      },
      setIdleMovement(frequency, amplitude = ''){
        window.setIdleMovement(frequency, amplitude)
      },
      setSpeechMovement(amplitude){
        window.setSpeechMovement(amplitude)
      },
      setBlinking(frequency){
        window.setBlinking(frequency);
      },
      loadAudio(name){
        window.loadAudio(name); 
      },
      loadText(txt,voice,lang,engine,effect = '', effLevel = ''){
        window.loadText(txt,voice,lang,engine,effect, effLevel);
      },
      sayAIResponse (txt,voice,lang,engine,botID = '', effect  = '', effLevel = ''){
        window.sayAIResponse (txt,voice,lang,engine,botID,effect,effLevel)
      },
      saySilent(seconds){
        window.saySilent(seconds);
      },
      setPlayerVolume(level){
        window.setPlayerVolume(level);
      },
      stopSpeech(){
        window.stopSpeech();
      },
      replay(){
        window.replay(); 
      },
      getSceneAttributes(){
        window.getSceneAttributes();
      },
      setBackground(bgName){
        window.setBackground(bgName);
      },
      setBackgroundColor(bgColor){
        window.setBackgroundColor(bgColor);
      },
      setColor(part,color){
        window.setColor(part,color);
      },
      setStatus(interruptMode,progressInterval,gazeSpeed,displayControls){
        window.setStatus(interruptMode,progressInterval,gazeSpeed,displayControls);
      },
      dynamicResize(width, height){
        window.dynamicResize(width, height);
      },
      is3D(){
        window.is3D();
      },
      overlayOpen(mode, play){
        window.overlayOpen(mode, play)
      },
      overlayClose(){
        window.overlayClose();
      },
      gotoNextScene(){
        window.gotoNextScene();
      },
      gotoPrevScene(){
        window.gotoPrevScene();
      },
      gotoScene(sceneRange){
        window.gotoScene(sceneRange);
      },
      preloadNextScene(){
        window.preloadNextScene();
      },
      preloadScene(sceneIndex){
        window.preloadScene(sceneIndex);
      },
      loadSceneByID(sceneID, slideID){
        window.loadSceneByID(sceneID, slideID); 
      },
      unloadScene(){
        window.unloadScene();
      },
      selectPortal(portal){
        window.selectPortal(portal);
      },
      vh_aiResponse(responseText, portal){
        window.vh_aiResponse(responseText, portal);
      },
      vh_audioLoaded(audioName, portal){
        window.vh_audioLoaded(audioName, portal);
      },
      vh_ttsLoaded(audioText, portal){
        window.vh_ttsLoaded(audioText, portal)
      },
      vh_audioProgress(percentPlayed, portal){
        window.vh_audioProgress(percentPlayed, portal);
      },
      vh_portalReady(portal){
        window.vh_portalReady(portal);
      },
      vh_sceneLoaded(sceneIndex, portal){
        window.vh_sceneLoaded(sceneIndex, portal);
      },
      vh_scenePreloaded(sceneIndex, portal){
        window.vh_scenePreloaded(sceneIndex, portal);
      },
      vh_talkStarted(portal){
        window.vh_talkStarted(portal);
      },
      vh_talkEnded(portal){
        window.vh_talkEnded(portal);
      },
      vh_audioStarted(portal){
        window.vh_audioStarted(portal);
      },
      vh_audioEnded(portal){
        window.vh_audioEnded(portal);
      },
      vh_playPause(status, portal){
        window.vh_playPause(status, portal);
      }






      
  }
</script>
